//----------------------------------------------------------------------//
// Module: Helper                                                       //
// Tasks: 1) Contains helper functions                                  //
// Author: Yash P Shah                                                  //
//----------------------------------------------------------------------//

import { PRINT_CATEGORIES } from "../configuration/constants";

import $ from "jquery";
import { toast } from "react-toastify";



/**
 * Refreshes the entire page
 */
export function refreshPage() {
  document.location.reload();
}

/**
 * Checks whether the browser being used is Google Chrome.
 */

export function isChrome() {
  var is_chrome =
    !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  sessionStorage.setItem("is_chrome", is_chrome);
}

/**
 * Exports the grid as CSV
 *
 * @param {Object} api
 * @param {String} file_name
 */
export function exportAsCSV(api, file_name) {
  let params = {
    skipHeader: false,
    columnGroups: true,
    allColumns: true,
    fileName: file_name,
    columnSeparator: ","
  };
  api.exportDataAsCsv(params);
}

/**
 * Validates the form.
 *
 * @param {Object} {form_errors}
 * @param  {...Object} rest
 */
export function isFormValid({ form_errors }, ...rest) {
  let valid = true;

  // Checks whether the state element 'form_errors' is empty or not.
  Object.values(form_errors).forEach(val => {
    val.length > 0 && (valid = false);
  });

  // validate the form is totally filled out.
  Object.values(rest).forEach(val => {
    val === null && (valid = false);
  });

  return valid;
}

/**
 * reset the filter(s).
 *
 * @param {Object} api
 */
export function resetFilter(api) {
  api.setFilterModel(null);
}

/**
 * Handles the page resize.
 *
 * @param {Object} newPageSize
 * @param {Object} api
 */
export function onPageSizeChanged(newPageSize, api) {
  const value = newPageSize.target.innerText;
  api.paginationSetPageSize(Number(value));

}

/**
 * Restore sort
 *
 * @param {Object} api
 */
export function resetSort(api) {
  api.setSortModel(null);
}

/**
 * When the Grid is ready.
 *
 *
 * @param {Object} params
 * @param {Object} api
 * @param {Object} column_api
 * @param {Object} initial_sort
 */
export function onGridReady(params, api, column_api, initial_sort) {
  //show the ag-grid loading UI.
  api.showLoadingOverlay();

  api.setSortModel(initial_sort);

  // @TODO: Uncomment if the Grid is required to have resizablity.
  /*
      api.sizeColumnsToFit();
      window.addEventListener("resize", () => {
        setTimeout(() => {
        params.api.sizeColumnsToFit();
       });
     });
     */

  //hide the ag-grid loading UI.
  api.hideOverlay();
}

/**
 * Returns the headers object with 'token_type' and 'access_token'.
 *
 * @returns {Object} headers_object
 */
export function getHeaders() {
  if (getSessionStoredJsonItem("user")) {
    let token_type = getSessionStoredJsonItem("user").token_type;
    let access_token = getSessionStoredJsonItem("user").access_token;
    let user_id = getSessionStoredJsonItem("user").user_id;
    if (!access_token) {
      return;
    }
    else {
      if (access_token && token_type) {
        return {
          headers: {
            Authorization: token_type + " " + access_token,
            Id: user_id
          }
        };

      }
    }
  }

}
export const Clinical_Prebiotic = [
  "flore_clinical_three_month_prebiotic",
  "flore_clinical_six_month_prebiotic",
  "flore_clinical_twelve_month_prebiotic",
  "flore_clinical_upgrade_two_month_prebiotic",
  "flore_clinical_upgrade_four_month_prebiotic",
  "flore_clinical_twelve_month_prebiotic",
  "flore_clinical_six_month_prebiotic",
  "flore_clinical_upgrade_two_month_prebiotic",
  "flore_clinical_upgrade_four_month_prebiotic",  
]
export const Clinical_Probiotic = [
  "flore_clinical_three_month_probiotic",
  "flore_clinical_six_month_probiotic",
  "flore_clinical_twelve_month_probiotic",
  "flore_clinical_upgrade_three_month_probiotic",
  "flore_clinical_upgrade_five_month_probiotic",
  "flore_clinical_twelve_month_probiotic",
  "flore_clinical_six_month_probiotic",
  "flore_clinical_upgrade_three_month_probiotic",
  "flore_clinical_upgrade_five_month_probiotic",
  "flore_clinical_three_month_probiotic",
]
export const Clinical_Type = [
  "flore_clinical_three_month_prebiotic",
  "flore_clinical_six_month_prebiotic",
  "flore_clinical_twelve_month_prebiotic",
  "flore_clinical_upgrade_two_month_prebiotic",
  "flore_clinical_upgrade_four_month_prebiotic",
  "flore_clinical_twelve_month_prebiotic",
  "flore_clinical_six_month_prebiotic",
  "flore_clinical_upgrade_two_month_prebiotic",
  "flore_clinical_upgrade_four_month_prebiotic",
  "flore_clinical_three_month_prebiotic",
  "flore_clinical_three_month_probiotic",
  "flore_clinical_six_month_probiotic",
  "flore_clinical_twelve_month_probiotic",
  "flore_clinical_upgrade_three_month_probiotic",
  "flore_clinical_upgrade_five_month_probiotic",
  "flore_clinical_twelve_month_probiotic",
  "flore_clinical_six_month_probiotic",
  "flore_clinical_upgrade_three_month_probiotic",
  "flore_clinical_upgrade_five_month_probiotic",
  "flore_clinical_three_month_probiotic",
  "test_only_clinical",
  "flore_clinical_three_month"
]
export const Product_Type = {
  "Regular Floré":"flore",
  "Regular Flore":"flore",
  "Regular Floré Tots":"flore_tots",
  "Regular Flore Tots":"flore_tots",
  "ASU Study":"asu_study",
  "Test only":"test_only",
  "Upgraded Test Only":"upgraded_test_only",
  "Monthly Floré Adult":"monthly_flore",
  "Monthly Floré Tots":"monthly_tots",
  "Nouveau Ireland":"nouveau_ireland",
  "Monthly Floré Baby":"monthly_flore_baby",
  "Monthly Floré Kids":"monthly_flore_kids",
  "COVID-19":"covid19",
  "Nouveau UK":"nouveau_uk",
  "Monthly Floré Earned":"monthly_earned",
  "Clinical Gut Test":"test_only_clinical",
  "Nouveau Health":"nouveau_health",
  "Floré Clinical (3 months)":"flore_clinical_three_month",
  "Flore Clinical (3 months)":"flore_clinical_three_month",
  "Floré International (3 months)":"flore_international_three_month",
  "Floré International (6 months)":"flore_international_six_month",
  "Flore International (3 months)":"flore_international_three_month",
  "Flore International (6 months)":"flore_international_six_month",
  "Floré Clinical (3 months) / probiotics":"flore_clinical_three_month_probiotic",
  "Floré Clinical (3 months) / prebiotics":"flore_clinical_three_month_prebiotic",
  "Floré Clinical (6 months) / prebiotics":"flore_clinical_six_month_prebiotic",
  "Floré Clinical (6 months) / probiotics":"flore_clinical_six_month_probiotic",
  "Floré Clinical (12 months) / prebiotics":"flore_clinical_twelve_month_prebiotic",
  "Floré Clinical (12 months) / probiotics":"flore_clinical_twelve_month_probiotic",
  "Floré Clinical Upgrade (2 months) / prebiotics":"flore_clinical_upgrade_two_month_prebiotic",
  "Floré Clinical Upgrade (3 months) / probiotics":"flore_clinical_upgrade_three_month_probiotic",
  "Floré Clinical Upgrade (4 months) / prebiotics":"flore_clinical_upgrade_four_month_prebiotic",
  "Floré Clinical Upgrade (5 months) / probiotics":"flore_clinical_upgrade_five_month_probiotic",
  "Floré Basic":"flore_basic",
  "Flore Basic":"flore_basic",
  "Regular Floré (International)":"flore_international",
  "Regular Flore (International)":"flore_international",
  "Flore Clinical (12 months) / prebiotics":"flore_clinical_twelve_month_prebiotic",
  "Flore Clinical (12 months) / probiotics":"flore_clinical_twelve_month_probiotic",
  "Flore Clinical (6 months) / prebiotics":"flore_clinical_six_month_prebiotic",
  "Flore Clinical (6 months) / probiotics":"flore_clinical_six_month_probiotic",
  "Flore Clinical Upgrade (2 months) / prebiotics":"flore_clinical_upgrade_two_month_prebiotic",
  "Flore Clinical Upgrade (3 months) / probiotics":"flore_clinical_upgrade_three_month_probiotic",
  "Flore Clinical Upgrade (4 months) / prebiotics":"flore_clinical_upgrade_four_month_prebiotic",
  "Flore Clinical Upgrade (5 months) / probiotics":"flore_clinical_upgrade_five_month_probiotic",
  "Flore Clinical (3 months) / probiotics":"flore_clinical_three_month_probiotic",
  "Flore Clinical (3 months) / prebiotics":"flore_clinical_three_month_prebiotic",
  "Monthly Flore Adult":"monthly_flore",
  "Monthly Flore Tots":"monthly_tots",
  "Monthly Flore Kids":"monthly_flore_kids",
  "Monthly Flore Baby":"monthly_flore_baby",
  "Monthly Flore Earned":"monthly_earned",
}

// list of product type
export const product_type = [
    "Regular Floré",
    "Regular Floré Tots",
    "ASU Study",
    "Test only",
    "Upgraded Test Only",
    "Monthly Floré Adult",
    "Monthly Floré Tots",
    "Nouveau Ireland",
    "Monthly Floré Baby",
    "Monthly Floré Kids",
    "COVID-19",
    "Nouveau UK",
    "Monthly Floré Earned",
    "Clinical Gut Test",
    "Nouveau Health",
    "Floré Clinical (3 months)",
    "Floré Clinical (6 months)",
    "Floré Clinical (12 months)",
    "Monthly Flore Adult",
    "Monthly Flore Tots",
    "Monthly Flore Kids",
    "Monthly Flore Baby",
    "Monthly Flore Earned",
    "Flore Clinical (6 months)",
    "Flore Clinical (12 months)",
    "Flore Clinical (3 months)",
]

export const monthly_product=[
  "monthly_flore",
      "monthly_tots",
      "monthly_flore_baby",
      "monthly_flore_kids",
      "monthly_earned",
      "monthly_flore",
      "monthly_tots",
      "monthly_flore_kids",
      "monthly_flore_baby",
      "monthly_earned"
]

export const payload_product_type = [
      "flore",
      "flore_tots",
      "asu_study",
      "test_only",
      "upgraded_test_only",
      "monthly_flore",
      "monthly_tots",
      "nouveau_ireland",
      "monthly_flore_baby",
      "monthly_flore_kids",
      "covid19",
      "nouveau_uk",
      "monthly_earned",
      "test_only_clinical",
      "nouveau_health",
      "flore_clinical_three_month",
      "flore_clinical_six_month",
      "flore_clinical_twelve_month",
      "monthly_flore",
      "monthly_tots",
      "monthly_flore_kids",
      "monthly_flore_baby",
      "monthly_earned",
      "flore_clinical_six_month",
      "flore_clinical_twelve_month",
      "flore_clinical_three_month"
    ];

export const product_type_array = [
  "Floré Clinical (3 months)",
  "Floré Clinical (6 months)",
  "Floré International (6 months)",
  "Floré Clinical (12 months)",
  "Monthly Floré Adult",
  "Monthly Floré Tots",
  "Monthly Floré Kids",
  "Monthly Floré Baby",
  "Monthly Floré Earned"
]
export const array_product_type = [
  "Floré Clinical (3 months)",
  "Floré Clinical (6 months)",
  "Floré Clinical (12 months)",
  "Flore International (6 months)",
  "Monthly Flore Adult",
  "Monthly Flore Tots",
  "Monthly Flore Kids",
  "Monthly Flore Baby",
  "Monthly Flore Earned"
]
export const clinical_validation_product_type = 
    [
      "Floré Basic",
      "Flore Basic",
      "Floré Clinical (12 months) / prebiotics",
      "Floré Clinical (6 months) / prebiotics",
      "Floré Clinical (3 months) / prebiotics",
      "Floré Clinical (12 months) / probiotics",
      "Floré Clinical (6 months) / probiotics",
      "Floré Clinical (3 months) / probiotics",
      "Flore Clinical (12 months) / prebiotics",
      "Flore Clinical (6 months) / prebiotics",
      "Flore Clinical (3 months) / prebiotics",
      "Flore Clinical (12 months) / probiotics",
      "Flore Clinical (6 months) / probiotics",
      "Flore Clinical (3 months) / probiotics",
      "Floré Clinical Upgrade (2 months) / prebiotics",
      "Floré Clinical Upgrade (3 months) / probiotics",
      "Floré Clinical Upgrade (4 months) / prebiotics",
      "Floré Clinical Upgrade (5 months) / probiotics",
      "Flore Clinical Upgrade (2 months) / prebiotics",
      "Flore Clinical Upgrade (3 months) / probiotics",
      "Flore Clinical Upgrade (4 months) / prebiotics",
      "Flore Clinical Upgrade (5 months) / probiotics",
    ]

export const validation_product_type = 
    [
      "Floré Clinical (12 months) / prebiotics",
      "Floré Clinical (6 months) / prebiotics",
      "Floré Clinical (3 months) / prebiotics",
      "Flore Clinical (12 months) / prebiotics",
      "Flore Clinical (6 months) / prebiotics",
      "Flore Clinical (3 months) / prebiotics",
      "Floré Clinical Upgrade (2 months) / prebiotics",
      "Floré Clinical Upgrade (3 months) / probiotics",
      "Floré Clinical Upgrade (4 months) / prebiotics",
      "Floré Clinical Upgrade (5 months) / probiotics",
      "Flore Clinical Upgrade (2 months) / prebiotics",
      "Flore Clinical Upgrade (3 months) / probiotics",
      "Flore Clinical Upgrade (4 months) / prebiotics",
      "Flore Clinical Upgrade (5 months) / probiotics",
    ]
export const validation_product_type_3_month = 
    [
      "Floré Clinical (3 months) / prebiotics",
      "Flore Clinical (3 months) / prebiotics",
    ]

export const product_type_3_month = 
[
  "Floré Clinical (3 months) / probiotics",
  "Flore Clinical (3 months) / probiotics",
]

export const product_type_probiotics_validation = 
    [
      "Floré Clinical (12 months) / probiotics",
      "Floré Clinical (6 months) / probiotics",
      "Floré Clinical (3 months) / probiotics",
      "Flore Clinical (12 months) / probiotics",
      "Flore Clinical (6 months) / probiotics",
      "Flore Clinical (3 months) / probiotics",
    ]

    export const product_type_six_twelve_validation = 
    [
      "Floré Clinical (12 months) / probiotics",
      "Floré Clinical (6 months) / probiotics",     
      "Flore Clinical (12 months) / probiotics",
      "Flore Clinical (6 months) / probiotics",
      "Floré Clinical (12 months) / prebiotics",
      "Floré Clinical (6 months) / prebiotics",
      "Flore Clinical (12 months) / prebiotics",
      "Flore Clinical (6 months) / prebiotics",
      
    ]

    export const probiotic_product_type = 
    [
      "Floré Clinical (12 months) / probiotics",
      "Floré Clinical (6 months) / probiotics",     
      "Flore Clinical (12 months) / probiotics",
      "Flore Clinical (6 months) / probiotics",     
      
    ]

    export const validation_clinical_product_type = 
    [
      "Floré Clinical (12 months) / prebiotics",
      "Floré Clinical (6 months) / prebiotics",
      "Floré Clinical (3 months) / prebiotics",
      "Flore Clinical (12 months) / prebiotics",
      "Flore Clinical (6 months) / prebiotics",
      "Flore Clinical (3 months) / prebiotics",
      "Floré Clinical (12 months) / probiotics",
      "Floré Clinical (6 months) / probiotics",
      "Floré Clinical (3 months) / probiotics",
      "Flore Clinical (12 months) / probiotics",
      "Flore Clinical (6 months) / probiotics",
      "Flore Clinical (3 months) / probiotics",
      "Floré Clinical Upgrade (2 months) / prebiotics",
      "Floré Clinical Upgrade (3 months) / probiotics",
      "Floré Clinical Upgrade (4 months) / prebiotics",
      "Floré Clinical Upgrade (5 months) / probiotics",
      "Flore Clinical Upgrade (2 months) / prebiotics",
      "Flore Clinical Upgrade (3 months) / probiotics",
      "Flore Clinical Upgrade (4 months) / prebiotics",
      "Flore Clinical Upgrade (5 months) / probiotics",
    ]

    export const six_twelve_month_wise_validation = {
      probiotics:3,
      prebiotics:2,     
    }


  export const month_wise_validation = {
    probiotics:5,
    prebiotics:4,
    prebiotics_3:2,
    probiotics_3:3,
  }


/**
 * Prints the API calls.
 *
 * @param {String} title
 * @param {Object} print_category
 * @param {String} data
 * @param {Object} payload
 */
export function customPrint(title, print_category, data, payload) {
  if (print_category === PRINT_CATEGORIES.REQUEST) {

  } else if (print_category === PRINT_CATEGORIES.RESPONSE) {
  }
}

/**
 * Converts inventory-options JSON Object:
 * ({id:'1',qty:'30',..},{id:'2',qty:'10',...},...)
 * to
 * inventory(1:..., 2:..., 3:...)
 *
 * @param {Object} inventoryDataJSON
 */
export function convertReqdInventoryOptionsJSON(inventoryDataJSON) {
  let res = {};

  inventoryDataJSON.map(inventory => {
    res[inventory.id] = {
      scrap_factor: inventory.scrap_factor,
      qty: inventory.qty,
      concentration: inventory.concentration,
      organism_barcode: inventory.organism_barcode
    };
    return inventory;
  });

  return res;
}

/**
 * convert the roles JSON.
 * [{"id":1, "name":"admin"}, {"id":2, "name":"op"}, ...]
 * to
 * {1:'admin', 2:'op', 3:'scientist', ...}
 *
 * @param {Object} roles_json
 */
export function convertRolesJSON(roles_json) {
  let res = {};
  roles_json.map(role => {
    res[role.id] = role.name
      .charAt(0)
      .toUpperCase()
      .concat(role.name.substring(1));
    return role;
  });

  return res;
}

/**
 * Converts the user_options JSON.
 *
 * @param {Object} user_options_json
 */
export function convertUserOptionsJSON(user_options_json) {
  let res = {};
  user_options_json.map(user => {
    res[user.id] = user.full_name;
    return user;
  });

  return res;
}

export function convertUserNameOptionsJSON(user_options_json) {
  let res = {};

  user_options_json.map(user => {
    res[user.id] = user.full_name;
    return user;
  });
  return res;
}
/**
 * Converts the inventory JSON.
 *
 * @param {Object} inventory_options_json
 */
export function convertInventoryOptionsJSON(inventory_options_json) {
  let res = {};
  inventory_options_json.map(inventory_item => {
    res[inventory_item.id] = inventory_item.organism_barcode;
    return inventory_item;
  });

  return res;
}

/**
 * Compares the date objects for sorting.
 *
 * @param {Date} date1
 * @param {Date} date2
 */
export function dateComparator(date1, date2) {
  let date1Number = monthToComparableNumber(date1);
  let date2Number = monthToComparableNumber(date2);

  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }

  let result = date1Number - date2Number;

  if (result < 0) return -1;
  if (result > 0) return 1;
  return 0;
}

/**
 * Converts the date into a numeric format for comparison purposes.
 *
 * @param {Date} date
 */
const monthToComparableNumber = date => {
  if (date === undefined || date === null) {
    return null;
  }
  // Format: yyyy-mm-dd
  let yearNumber = date.substring(0, 4);
  let monthNumber = date.substring(5, 7);
  let dayNumber = date.substring(8, 10);

  let result = yearNumber * 10000 + monthNumber * 100 + dayNumber * 1;

  if (date.length > 10) {
    let hourNumber = date.substring(11, 13);
    let minuteNumber = date.substring(14, 16);
    let secondNumber = date.substring(17, 19);

    let timeResult = hourNumber * 10000 + minuteNumber * 100 + secondNumber * 1;
    result = result * 1000000 + timeResult;
  }

  return result;
};

/**
 * Fetches the stores session JSON item.
 *
 * @param {String} item
 */
export function getSessionStoredJsonItem(item) {

  return JSON.parse(sessionStorage.getItem(item));
}

/**
 * Fetches the role of the user stored in session.
 */
export function getUserRole() {
  let user_object = getSessionStoredJsonItem("user");

  if (user_object) return user_object.role_name.toLowerCase();
  return "";
}

export const floatOption  = [
  {
    product_type: "",
  },
  {
    product_type: "Regular Floré",
  },
  {
    product_type: "Floré International (3 months)",
  },
  {
    product_type: "Floré International (6 months)",
  },
  {
    product_type: "Flore International (3 months)",
  },
  {
    product_type: "Flore International (6 months)",
  },
  {
    product_type: "Floré Clinical (3 months)",
  },
  {
    product_type: "Flore Clinical (3 months)",
  },
  {
    product_type: "Regular Floré Tots",
  },
  {
    product_type: "ASU Study",
  },
  {
    product_type: "Test only",
  },
  {
    product_type: "Upgraded Test Only",
  },
  {
    product_type: "Monthly Floré Adult",
  },
  {
    product_type: "Monthly Floré Tots",
  },
  {
    product_type: "COVID-19",
  },
  {
    product_type: "Nouveau Ireland",
  },
  {
    product_type: "Nouveau UK",
  },
  {
    product_type: "Monthly Floré Baby",
  },
  {
    product_type: "Monthly Floré Kids",
  },
  {
    product_type:"Clinical Gut Test"
  },
  {
    product_type:"Monthly Floré Earned"
  },
  {
    product_type:"Floré Basic"
  },
  {
    product_type:"Flore Basic"
  },
  {
    product_type:"Nouveau Health"
  },
  {
    product_type:"Floré Clinical (6 months) / prebiotics"
  },
  {
    product_type:"Floré Clinical (6 months) / probiotics"
  },
  {
    product_type:"Floré Clinical (12 months) / probiotics"
  },
  {
    product_type:"Floré Clinical (12 months) / prebiotics"
  },
  {
    product_type:"Floré Clinical (3 months)"
  },
  {
    product_type:"Flore Clinical (3 months)"
  },
  {
    product_type:"Floré Clinical (3 months) / probiotics"
  },
  {
    product_type:"Floré Clinical (3 months) / prebiotics"
  },
  {
    product_type:"Regular Floré (International)"
  },
  {
    product_type:"Floré International (6 months)"
  },
  {
    product_type:"Flore International (6 months)"
  },

  { product_type:"Floré Clinical Upgrade (2 months) / prebiotics"
  },
  {
    product_type:"Floré Clinical Upgrade (3 months) / probiotics"
  },
  {
    product_type:"Floré Clinical Upgrade (4 months) / prebiotics"
  },
  {
    product_type:"Floré Clinical Upgrade (5 months) / probiotics"
  }
];
export function getUserId() {
  let user_object = getSessionStoredJsonItem("user");
  if (user_object) return user_object.role_id;
  return "";
}

/**
 * Deep clones the object passed.
 *
 * @param {Object} arr
 */
export function deepClone(arr) {
  return arr ? JSON.parse(JSON.stringify(arr)) : {};
}

export function changeAvailablityOfOrganism(value) {
  const isAvailable_Value = [
    { value: "Not Available", data: 0 },
    { value: "Available", data: 1 },
    { value: "Quarantined", data: 2 },
    { value: "Sample", data: 3 }
  ];
  var available_value = isAvailable_Value.find((item) => item.value == value);
  return {
    is_available: available_value.data,
    is_avaialble_value: available_value.value
  }
};

// format Date
export function formatDate(date) {
  var day = date.getDate();
  if (day < 10) {
      day = "0" + day;
  }
  var month = date.getMonth() + 1;
  if (month < 10) {
      month = "0" + month;
  }
  var year = date.getFullYear();
  return day + "/" + month + "/" + year;
}


/**
 * Focuses the first element.
 */
export function focusFirstElement() {
  $("input:text:visible:first").focus();
  return;
}

/**
 * Converts and returns the date object to mm/dd/yyyy string
 *
 * @param {Date} date
 */
export function getDateString(date) {
  return (
    (date.getMonth() < 9 ? "0" : "") +
    (date.getMonth() + 1) +
    "/" +
    (date.getDate() < 10 ? "0" : "") +
    date.getDate() +
    "/" +
    date.getFullYear()
  );
}

/**
 * Increase the date year by one for Inventory's 'Expiry Date'.
 *
 * @param {Date} date
 */
export function increaseDateYear(date) {
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();
  return new Date(year + 1, month, day);
}

/**
 * Simplify the formulas for display purposes.
 * @param {Object} formulas
 */
export function simplifyFormulationsData(formulas) {
  let res = [];

  formulas.forEach(formula => {

    //formulations fields
    let id = formula.id;
    let sample_id = formula.sample_id;
    let formula_id = formula.formula_id;
    let date_created = formula.date_created;
    let date_made = formula.date_made;
    let lot = formula.lot;

    let prime_powder_remaining = formula.prime_powder_remaining;
    let prime_fill_qc_weight = formula.prime_fill_qc_weight;
    let prime_total_capsules_made = formula.prime_total_capsules_made;
    let prime_capsule_size = formula.prime_capsule_size;
    let payment_type = formula.payment_type

    let flourish_powder_remaining = formula.flourish_powder_remaining;
    let flourish_fill_qc_weight = formula.flourish_fill_qc_weight;
    let flourish_total_capsules_made = formula.flourish_total_capsules_made;
    let flourish_capsule_size = formula.flourish_capsule_size;

    let prime_flourish_amount_added = formula.prime_flourish_amount_added;
    let prime_filler_inventory_id = formula.prime_filler_inventory_id;
    let prime_filler_amount_added = formula.prime_filler_amount_added;
    let prime_total_amount_added = formula.prime_total_amount_added;

    let admin_notes = formula.admin_notes;
    let operator_notes = formula.operator_notes;
    let is_prime = formula.is_prime;
    let product_type = formula.product_type

    let number_of_months = formula.number_of_months;
    let total_amount_added = formula.total_amount_added;
    let total_final_concentration = formula.total_final_concentration;
    let total_final_amount_added = formula.total_final_amount_added;
    let user_id = formula.user_id;
    let user_Id = formula.user_id;
    let full_name = formula.user ? formula.user.full_name : '';
    let prime_filler_barcode = (formula.inventory_barcode && formula.inventory_barcode !== null && formula.inventory_barcode.length > 0) ? formula.inventory_barcode[0].organism_barcode : '';
    let formulation_type = formula.type;
    let is_completed = formula.is_completed;
    let formulation_factor = formula.formulation_factor;

    formula.organisms.forEach(organism => {
      //organisms fields
      if (!organism.inventory) return;

      let organisms_id = organism.id;
      let organism_barcode = organism.inventory.organism_barcode;
      let amount_added = organism.amount_added;

      let scrap_amount = organism.scrap_amount;
      let total_cfu = organism.total_cfu;
      let final_concentration = organism.final_concentration;
      let final_amount_added = organism.final_amount_added;
      let concentration = organism.inventory.concentration;
      let inventory_id = organism.inventory_id;
      let created_at = organism.created_at;
      let updated_at = organism.updated_at;
      let deleted_at = organism.deleted_at;

      let resObj = {
        id,
        sample_id,
        formula_id,
        date_created,
        date_made,
        lot,
        formulation_type,
        prime_powder_remaining,
        prime_fill_qc_weight,
        prime_total_capsules_made,
        prime_capsule_size,
        payment_type,
        flourish_powder_remaining,
        flourish_fill_qc_weight,
        flourish_total_capsules_made,
        flourish_capsule_size,

        prime_flourish_amount_added,
        prime_filler_inventory_id,
        prime_filler_amount_added,
        prime_total_amount_added,

        prime_filler_barcode,

        admin_notes,
        operator_notes,
        is_prime,
        product_type,
        number_of_months,
        total_amount_added,
        total_final_concentration,
        total_final_amount_added,
        user_id,
        full_name,
        user_Id,
        is_completed,
        organisms_id,
        organism_barcode,
        amount_added,
        formulation_factor,
        scrap_amount,
        total_cfu,
        final_concentration,
        final_amount_added,
        concentration,
        inventory_id,
        created_at,
        updated_at,
        deleted_at
      };
      res.push(resObj);
    });


  });
  return res;
}
export function simplifyFormulationsOrganismData(formulas) {
  let res = [];

  formulas.forEach(formula => {

    //formulations fields
    let organism_id = formula.id;
    let organism_barcode = formula.organism_barcode;
    let organism_name = formula.name;
    let tax_id = formula.tax_id;
    let resObj = {
      organism_id,
      organism_barcode,
      organism_name,
      tax_id,

    };
    res.push(resObj);
  });
  return res;
}
export function simplifyModulePermissionData(data) {

  let res = [];

  data.forEach(details => {

    //formulations fields
    let permissionId = details.id;
    let roleId = details.role_id;
    let module_name = details.modules;
    let view_permission = details.right_view;
    let edit_permission = details.right_edit;
    let delete_permission = details.right_delete;
    let role_name = details.role_name;

    let resObj = {
      permissionId,
      roleId,
      module_name,
      view_permission,
      edit_permission,
      delete_permission,
      role_name,

    };
    res.push(resObj);
  });
  return res;
}

export function digitsAfterPoint() {
  $(document).on('keypress', 'input[pattern]', function (e) {
    var input = $(this);
    var oldVal = input.val();
    let checkLength = oldVal.split(".")[1];
    if (checkLength !== undefined && checkLength.length > 3) {
      return false;
    }
    var regex = new RegExp(input.attr('pattern'), 'g');
    setTimeout(function () {
      var newVal = input.val();
      if (!regex.test(newVal)) {
        input.val(oldVal);
        return;
      }
    }, 0);
  });
}

export function digitsAfterPointAddIngredient() {
  $('#NumericTextBox').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function digitsAfterPointOrganism() {
  $('#amount_added').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function prime_filler_amount_added() {
  $('#prime_filler_amount_added').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function digitsAfterPrimeFlourish() {
  $('#prime_flourish_amount_added').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function digitsAfterPointPrime() {
  $('#NumericTextBox_prime').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function exsitingPreDigits() {
  $('#NumericTextBox_pre').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function exsitingPreDigitsSelect() {
  $('#amount_added_select').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function amountPerServing() {
  $('#NumericTextBoxAmountPerServing').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function startFormulation() {
  $('#flourish_fill_qc_weight').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}


export function filledTube() {
  $('#filled_tube').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function totalCapsuleMade() {
  $('#flourish_total_capsules_made').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function flourishPowederRemaining() {
  $('#flourish_powder_remaining').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}

export function PrimefillQcWeight() {
  $('#prime_fill_qc_weight').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}


export function PrimeTotalCapsuleMade() {
  $('#prime_total_capsules_made').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}


export function PrimePowderRemaining() {
  $('#prime_powder_remaining').keypress(function (event) {

    if ((event.which != 46 || $(this).val().indexOf('.') != -1) && (event.which < 48 || event.which > 57)) {

      event.preventDefault();
    }
    if (this.value.indexOf(".") > -1 && (this.value.split('.')[1].length > 2)) {

      event.preventDefault();
    }
  });
}


export function notEnterZeroBefore(e) {
  let code = (e.which) ? e.which : e.keyCode;
  if (code > 31 && (code < 48 || code > 57)) {
    e.preventDefault();
  }

  $("#answer_key_press").on("input", function () {
    if (/^0/.test(this.value)) {
      this.value = this.value.replace(/^0/, "")
    }
  });
}
export function multi_v_availablity(type,value,previousMulti_v_availblity){
  var multi_value = {};
    if(type == "radio"){
      if(previousMulti_v_availblity == "available"){
        multi_value = {multi_v:'unavailable',ingredient_type:"",multi_v_gram:""}
      }else{
        multi_value = {multi_v:"available"}
      }
    }
    if(type == "ingredient_type"){
      if(value == 'Select Ingredient Type'){
        alert("Select ingredient type value");
        return;
      }
      multi_value = {ingredient_type:value}
    }
    if(type == 'multi_v_gram'){
      // var t = value;

      // multi_value = {multi_v_gram:(t.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t}
      multi_value = {multi_v_gram:value}
    }
    return multi_value;
}

export function checkValueExistOrNotInMultiVFun(multi_v,ingredient_type,multi_v_gram){
  var x = [multi_v,ingredient_type,multi_v_gram];
  var flag  = false;

  x.map((item)=>{
    if([null,"",undefined].indexOf(item)>-1){
      flag = true;
    }
  })

  return {unavailable:!(multi_v =='available') ,checked:flag,info:"Fill Multi-V info"};
  
}

export function errorHandler(msg) {
  toast.error(msg);
}
export const isAvailable_lower = [
  { value: "Select Ingredient Type",disabled:true},
  { value: "Filler" },
  { value: "Vitamin" },
];

export const line_equipment_array=[
 ['hood1','scale1','computer1'],
 ['hood2','scale2','piller1','computer2'],
 ['hood3','scale3','piller2','computer3'],
 ['hood4','scale4','computer4'],
]

export const equipmentList=[
  [],
  [{equipment_id:"Hood #1",equipment_desc:"Biosafety Hood",asset_number:1093},{equipment_id:"Scale #1",equipment_desc:"100g scale",asset_number:1210},{equipment_id:"Computer #1",equipment_desc:"Chromebook",asset_number:1226}],
  [{equipment_id:"Hood #2",equipment_desc:"Biosafety Hood",asset_number:1005},{equipment_id:"Scale #2",equipment_desc:"100g scale",asset_number:1208},{equipment_id:"Piller #1",equipment_desc:"100 count piller",asset_number:""},{equipment_id:"Computer #2",equipment_desc:"Surface",asset_number:1225}],
  [{equipment_id:"Hood #3",equipment_desc:"Biosafety Hood",asset_number:1092},{equipment_id:"Scale #3",equipment_desc:"100g scale",asset_number:''},{equipment_id:"Piller #2",equipment_desc:"100 count piller",asset_number:""},{equipment_id:"Computer #3",equipment_desc:"HP",asset_number:''}],
  [{equipment_id:"Hood #4",equipment_desc:"Biosafety Hood",asset_number:1158},{equipment_id:"Scale #4",equipment_desc:"300g scale",asset_number:1098},{equipment_id:"Piller #3",equipment_desc:"200 count piller",asset_number:""}]
 ]

 export function isClinical(product_type){
    let is_clinical = false;
    if(clinical_validation_product_type.includes(product_type)){
      is_clinical=true;
    }
    return is_clinical;
 }

 export function isPrebiotic(product_type){
  let is_prebiotic = false;
  if(Clinical_Prebiotic.includes(product_type)){
    is_prebiotic=true;
  }
  return is_prebiotic;
}
export function isProbiotic(product_type){
  let is_probiotic = false;
  if(Clinical_Probiotic.includes(product_type)){
    is_probiotic=true;
  }
  return is_probiotic;
}

export const loadImage = (imageName) => {
  const image = require(`./images/${imageName}`); 
  return image;
};
export const adult_info=[
  {package_material:"PAK_000236",description:"Rigid Pill Box Adult",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000349",description:"1g Dessicant",quantity:"",barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000300",description:"Adult Insert Cart",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000243",description:"Adult Sachet 30 pills",quantity:"",barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
]
export const kids_info=[
  {package_material:"PAK_000242",description:"Rigid Pill Box Kids",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000349",description:"1g Dessicant",quantity:"",barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000330",description:"Kids Insert Cart",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000246",description:"Kids Sachet 30 pills",quantity:"",barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
]

export const tots_info=[
  {package_material:"PAK_000241",description:"Rigid Pill Box tots",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000349",description:"1g Dessicant",quantity:"",barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000329",description:"Tots Insert Cart",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000245",description:"Tots Sachet 30 pills",quantity:"",barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
]

export const baby_info=[
  {package_material:"PAK_000240",description:"Rigid Pill Box baby",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000349",description:"1g Dessicant",quantity:"",barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000328",description:"Baby Insert Cart",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000244",description:"Baby Sachet 30 pills",quantity:"",barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
]

export const prebiotic_info=[
  {package_material:"PAK_000413",description:"Prebiotic Bulk Pouches",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"GEN_000380",description:"Prebiotic 10ml scoop",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"MAN_000440",description:"dri-card desiccant",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}}
]

export const clinical_info=[
  {package_material:"PAK_000404",description:"Rigid Pill Box 3inch - Clinical",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000349",description:"1 g desiccant",quantity:3,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000441",description:"Clinical Con-bon Month 1",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000442",description:"Clinical Con-bon Month 2",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000443",description:"Clinical Con-bon Month 3",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}},
  {package_material:"PAK_000409",description:"clinical 30 day sachet",quantity:3,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}}
]

export const clinical_info_prime=[
  {package_material:"PAK_000407",description:"Clinical Sachets - 7 day",quantity:1,barcode:"",primary_operator:"",secondary_operator:"",primary_operator_name:"",secondary_operator_name:"",validationErrors:false,formErrors:{barcode:false,primary_operator:false,secondary_operator:false,quantity:false}}
]

export const transformYeld = (yeld) => {
  return `± ${ yeld.replace('-', '')}`;
};
export const validateYield = (measured,traget_amount) => {
  let is_vailid = true;  
  let measuredValue = parseFloat(measured);
  let upperBound = (traget_amount + 0.1).toFixed(2);
  let lowerBound = (traget_amount - 0.1).toFixed(2);
  console.log(measuredValue > upperBound)
  if (measuredValue < lowerBound || measuredValue > upperBound) {
    toast.error(`The measured amount is outside the acceptable yield`);
    is_vailid = false;
  } 
  return is_vailid;
};
