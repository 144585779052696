import React, { Component } from 'react'
import { Form, Input, TextArea } from "semantic-ui-react";
import axios from "axios";
import { baseURL } from "../../../../configuration/constants";
import { getHeaders, loadImage } from "../../../helper";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

class PillingofMixedFlourishIngredient extends Component {
    state = { performed_by_pin: "", performed_by: "", performed_by_name: "", total_amount: 0, months: 0, step_prev: "pill_weight_of_prime_powder", step_next: 'flourish_pill_count', primary_operator: "", primary_operator_name: "", formErrors: { performed_by_pin: false, primary_operator: false, barcode: false, measured: false } }

    componentDidMount() {
        this.getCurrentStep('pilling_of_mixed_flourish_ingredient');
        this.setState({ total_amount: (this.props.selected_row_value.number_of_months * 30) + 10 });

        // if (this.props.selected_row_value.is_prime === 'Yes') {
        //     if (this.props.selected_row_value.flourish_capsule_size !== "powder") {
        //         this.setState({ step_next: "pill_weight_of_prime_powder" })
        //     }
        // }

        if (this.props.selected_row_value.is_prime === 'No') {
            if (this.props.selected_row_value.flourish_capsule_size !== "powder") {
                this.setState({step_prev: "weight_of_mixed_flourish_ingredients"})
                // if (this.props.selected_row_value.product_type === "monthly_flore") {
                //     this.setState({ step_next: "flourish_pill_count" , step_prev: "weight_of_mixed_flourish_ingredients"})
                // } else {
                //     this.setState({ step_next: "equipment_sanitization" })
                // }
            }
        }

    }
    getCurrentStep = (step_name) => {
        let req = {
            formula_id: this.props.selected_row_value.formula_id,
            step_name: step_name
        }
        axios.post(baseURL + "/get-current-step", req, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true && result.current_step !== null) {
                    let current_step = result.current_step;
                    let step_json = JSON.parse(current_step.step_json);
                    console.log(step_json);
                    this.setState({ step_current: current_step });
                    this.onInputChange(null, { name: "performed_by_pin", "value": current_step.performed_by });
                    this.onInputChange(null, { name: "barcode", "value": step_json.barcode });
                    this.onInputChange(null, { name: "measured", "value": step_json.measured });
                    this.onInputChange(null, { name: "primary_operator", "value": step_json.primary_operator });
                    this.onInputChange(null, { name: "operator_notes", "value": step_json.operator_notes });
                }
            })
    }

    handleClick = () => {
        // Call the function passed from the parent
        this.props.onPrevClick(this.state.step_prev);
    };
    handleNextClick = () => {
        // Call the function passed from the parent
        this.props.onNextClick(this.state.step_next);
    };

    handlePauseClick = () => {
        this.props.onNextClick('pause');
    }
    handleQuitClick = () => {
        this.props.onNextClick('quit');
    }

    onInputChange = (e, { name, value }) => {
        console.log(name);
        switch (name) {
            case "performed_by_pin":
                if (value.length >= 3) {
                    this.getPerformUser(value, "performer");
                } else {
                    this.setState({ performed_by: "", performed_by_name: "" });
                }
                break;
            case "primary_operator":
                this.setState({ primary_operator: value });
                if (value.length >= 3) {

                    this.getPerformUser(value, "primary_operator");

                } else {
                    this.setState({ primary_operator_name: "" });
                }
                break;
            default:
                break;


        }

        this.setState({ [name]: value });

    };

    getPerformUser = (pin, user_type) => {
        if (!pin) {
            toast.error("Please fill pin number.");
            return;
        }
        axios.post(baseURL + "/users/getUserByPin", { pin: pin }, getHeaders())
            .then(res => {
                if (res.data.success === true && res.data.user !== null) {
                    let user = res.data.user;
                    switch (user_type) {
                        case "performer":
                            this.setState(prevState => ({
                                performed_by: user ? user.id : "",
                                performed_by_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    performed_by_pin: user ? false : true
                                }
                            }));
                            break;
                        case "primary_operator":
                            this.setState(prevState => ({
                                primary_operator_name: user ? user.full_name : "",
                                formErrors: {
                                    ...prevState.formErrors,
                                    primary_operator: user ? false : true
                                }
                            }));
                            break;
                        default:
                            break;
                    }
                    //this.setState({user_full_name:user.full_name}); 
                }
            })
    }

    handleSubmit = () => {
        let input_field = false;
        if (this.state.measured === "" || this.state.primary_operator === "" || this.state.primary_operator_name === "") {
            input_field = true;
        }

        this.setState((prevState) => ({
            formErrors: {
                ...prevState.formErrors, // spread the existing formErrors
                performed_by_pin: !this.state.performed_by_name ? true : false,
                primary_operator: !this.state.primary_operator_name ? true : false,
                measured: !this.state.measured ? true : false,
                barcode: !this.state.barcode ? true : false
            }
        }));

        if (input_field) {
            toast.error("Please complete all required field(s) ");
            return;
        }

        if (!this.state.performed_by_pin) {
            toast.error("PIN is missing");
            return;
        }

        if ((!this.state.performed_by_name && this.state.performed_by_pin)) {
            toast.error("PIN is incorrect");
            return;
        }

        if (!this.state.barcode.includes("MAN_000031_")) {
            toast.error("Please enter vaild barcode");
            return
        }


        let step_json = {
            capsule_number: "MAN_000031",
            total_amount: this.state.total_amount,
            operator_notes: this.state.operator_notes,
            measured: this.state.measured,
            barcode: this.state.barcode,
            primary_operator: this.state.primary_operator,
            secondary_signoff: this.state.secondary_signoff
        }
        let param = {
            sample_id: this.props.selected_row_value.sample_id,
            formula_id: this.props.selected_row_value.formula_id,
            step: 17,
            step_json: JSON.stringify(step_json),
            step_name: 'pilling_of_mixed_flourish_ingredient',
            step_next: this.state.step_next,
            performed_by: this.state.performed_by_pin
        }
        axios.post(baseURL + "/mmr-formulations", param, getHeaders())
            .then(res => {
                let result = res.data;
                if (result.success === true) {
                    toast.success("Successfully saved Step");
                    this.props.onNextStep();
                } else {
                    toast.error("Step has not saved");
                }
            })
    }
    render() {
        const { formErrors } = this.state
        return (
            <>
                <Form onSubmit={this.handleSubmit}>
                    <div className='cs-box'>
                        <div className='cs-body'>
                            <h3>Pilling of Mixed Flourish Ingredients</h3>
                            <div className='d-flex mt-2'>
                                <div className='w-60 border-r'>
                                    <ul className='cs-list'>
                                        <li>Fill Pills:  </li>
                                        <li>Place plastic edge guard onto 100 count pill makers. </li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Pour approximately 3/4s of mixed powder onto pill bottoms.</li>
                                    </ul>
                                    <br />
                                </div>
                                <div className='w-40 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/1.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/2.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Using rubber scraper work powder into pill bottoms. </li>
                                    </ul>
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/3.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <ul className='cs-list'>
                                        <li>Compress power with tamper. </li>
                                    </ul>
                                    <ul className='cs-list'>
                                        <li>Repeat steps previous two steps three (3) times adding more powder as necessary.</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Place extra powder back into the shaker.</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Place the top of pilling machine back onto bottom section. </li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Squeeze bottom plate up to recombine the top and bottom of the pills. </li>
                                    </ul>
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/4.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/5.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/6.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Remove the top unit with silver handles and unlock plastic cover to remove Flourish pills. </li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Brush off excess powder from surface of pill maker. </li>
                                    </ul>
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Remove filled capsules from pilling equipment and place in large weigh boat. </li>
                                    </ul>
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/7.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/8.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/9.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <ul className='cs-list'>
                                        <li>Six-month: </li>
                                        <li>Pour powder into bed of machine and spread evenly across. Making sure all capsules are filled. </li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Use tamper to compact powder into capsules. With both hands evenly apply pressure.</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>9.2.3 Repeat steps 9.2.1 and 9.2.2 until powder can no longer be compacted into capsule.</li>
                                        <li>9.2.4 Take off guard and scrape off left over powder into weigh boat and record weight and put into formulation website. </li>
                                    </ul>
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/10.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/11.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>9.2.5 Place guard back on machine and put top part of capsules back on machine. Align corners with notch for correct fitting. </li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/12.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            {/* 14/15 */}
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>9.2.6 Evenly apply pressure to make sure the capsules are properly closed. Repeat 3 times to ensure a tight fit. </li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>9.2.7 Flip over tray making sure the plate is pressed filmy against the pills, so they don’t fall out while flipping over. </li>
                                        <li>9.2.8 With operator’s hand apply a gentle force evenly so capsules are tightened once again.</li>
                                    </ul>
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/13.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/14.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            {/* 16/17 */}
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>9.2.9 Get a new weigh boat and place underneath tray. Remove the top sheet so capsules can be transferred into a weigh boat.</li>
                                    </ul>
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/15.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/16.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/17.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            {/* 18/19 */}
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <ul className='cs-list'>
                                        <li>Setting up pill maker for Prime:</li>
                                    </ul>
                                    <br />
                                    <ul className='cs-list'>
                                        <li>NOTE: ONLY MIX PRIME IF REQUESTED IN FORMULATION.SUNGENOMICS. COM</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Change gloves</li>
                                        <li>Place 10 empty capsules into pill maker with skinny end down in two rows of 5.</li>
                                    </ul>
                                    <ul className='cs-list'>
                                        <li>Turn lever until pills are tight in fixture.</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Separate tops from bottoms of capsules with same method described in 6.8.</li>
                                        <li>Set tops aside and proceed to mix Prime as described in section 13.</li>
                                        <li>Making Prime:</li>
                                    </ul>
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/18.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/19.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            {/* Second */}
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <ul className='cs-list'>
                                        <li>Weigh 1.2g of Flourish with 4.8g of filler as called for in recipe from formulations.sungenomics.com.</li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Combine in single weigh boat and stir with clean plastic spoon until thoroughly mixed.</li>
                                    </ul>
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/20a.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/20.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2 d-flex'>
                                        <img style={{ width: "50%" }} src={loadImage("Step17/21.jpg")} alt='img' />
                                        <img style={{ width: "50%" }} src={loadImage("Step17/22.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <ul className='cs-list'>
                                        <li>Adding Prime to Pills:</li>
                                        <li>Place plastic mold over pill bottoms. </li>
                                        <li>Pour Prime into reservoir. </li>
                                        <li>Fill pills by using rubber scraper. </li>
                                        <li>Compress powder with tamper. </li>
                                        <li>Repeat steps 13.3 to 13.4 three (3) times. </li>
                                        <li>Place extra powder back into weigh boat.</li>
                                    </ul>
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/23.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex border-b'>
                                <div className='w-60 border-r'>
                                    <ul className='cs-list'>
                                        <li>Place top of pilling machine back onto bottom. </li>
                                        <li>Squeeze bottom plate upwards to recombine top and bottom of pills. </li>
                                        <li>Remove top unit by squeezing silver handles and top plate and remove Prime pills. </li>
                                    </ul>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <ul className='cs-list'>
                                        <li>Place all dirty equipment into wash bucket.</li>
                                    </ul>
                                </div>
                                <div className='w-40 pt-2 ps-5'>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/24.jpg")} alt='img' />
                                    </div>
                                    <div className='mb-2'>
                                        <img className='mxw-100' src={loadImage("Step17/25.jpg")} alt='img' />
                                    </div>
                                </div>
                            </div>

                            <div className='mt-5'>
                                <h4>Capsule Lot Record</h4>
                                <div className='custom-table custom-header-table'>
                                    <table className='w-100' cellPadding={0} cellSpacing={0}>
                                        <tbody>
                                            <tr>
                                                <th>Capsule Part <br />Number</th>
                                                <th>Target Amt</th>
                                                <th>Scan of Barcode</th>
                                                <th>Measured</th>
                                                <th>Primary Operator</th>
                                            </tr>
                                            <tr>
                                                <td>MAN_000031</td>
                                                <td className='text-green'>{this.state.total_amount}</td>
                                                <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                            error={formErrors.barcode}
                                                            id={`barcode`}
                                                            name="barcode"
                                                            onChange={this.onInputChange}
                                                            type="text"
                                                            value={this.state.barcode ? this.state.barcode : ""}
                                                            className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                                </td>

                                                <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                            error={formErrors.measured}
                                                            id={`measured`}
                                                            name="measured"
                                                            onChange={this.onInputChange}
                                                            value={this.state.measured ? this.state.measured : ""}
                                                            type="text"
                                                            className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                                </td>
                                                <td>
                                                    <Form.Field required fluid="true">
                                                        <Input
                                                            error={formErrors.primary_operator}
                                                            id={`primary_operator`}
                                                            name="primary_operator"
                                                            onChange={this.onInputChange}
                                                            value={this.state.primary_operator ? this.state.primary_operator : ""}
                                                            type="password"
                                                            className="full_opacity form-control w-100 text-center"
                                                        />
                                                    </Form.Field>
                                                    {
                                                        this.state.primary_operator_name && (
                                                            <div className="ui input">{this.state.primary_operator_name}</div>
                                                        )
                                                    }
                                                </td>

                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div><h4 className='m-0'>Operator Notes:</h4></div>
                                <div>
                                    <Form.Field required fluid="true">
                                        <TextArea

                                            id="operator_notes"
                                            name="operator_notes"
                                            onChange={this.onInputChange}
                                            value={this.state.operator_notes}
                                            placeholder="Operator Notes"
                                            className="notes white-textarea text-black"
                                        />
                                    </Form.Field>

                                </div>
                            </div>
                            <div className='text-center mt-5'>
                                <div className='d-inline-flex'>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'>Performed By: </h4></div>
                                        <div className='m-0 pe-1'>
                                            <h4 className='m-0'><u>{this.state.performed_by_name}</u></h4>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center'>
                                        <div><h4 className='m-0 pe-1'> PIN</h4></div>
                                        <div className='ui input'>
                                            <Form.Field required fluid="true">
                                                <Input
                                                    error={formErrors.performed_by_pin}
                                                    id="performed_by_pin"
                                                    name="performed_by_pin"
                                                    onChange={this.onInputChange}
                                                    value={this.state.performed_by_pin}
                                                    placeholder="Enter Your PIN"
                                                    type="password"
                                                    className="full_opacity"
                                                    autoComplete="new-password"
                                                />
                                            </Form.Field>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='cs-footer text-center'>
                            <hr />
                            <div className='text-center mt-2'>
                                <button type='button' onClick={this.handleClick} className='gray-btn' >Prev</button>
                                <button type='button' onClick={this.handlePauseClick} className='ui icon secondary labeled button pause-btn ps-1-5em ms-2'>Pause</button>
                                <button type='button' onClick={this.handleQuitClick} className='ui icon secondary labeled button quit-btn ps-1-5em ms-2'>Quit</button>
                                {this.props.step_name === this.state.step_prev && (<button type='submit' className='theme-btn ms-2' >Next</button>)}
                                {this.props.step_name !== this.state.step_prev && (<button type="button" onClick={this.handleNextClick} className="theme-btn ms-2" > Next </button>)}
                            </div>
                        </div>
                    </div>
                </Form>
            </>
        )
    }
}
export default PillingofMixedFlourishIngredient;